@import "../../../styles/mixins";

.ant-select-dropdown {
  max-width: 100% !important;
  min-width: unset !important;
}

.social_links-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 20px;
  color: #000;
}

.social__media--fields {
  flex-basis: 100% !important;

  .ant-input {
    max-width: 303px !important;
    margin-right: 47px !important;
    margin-bottom: 20px !important;

    @include mq('ipad') {
      margin-right: 0 !important;
      margin-top: 10px;
    }
  }
}


.rtl-label {
   .ant-form-item-label > label {
     direction: rtl !important;
   }
}