@import "../../styles/mixins";

.message__wrapper {
  border: solid 1px #e5e5e5;
  background: #fff;
  height: calc(100vh - 158px);
  border-radius: 10px;
  display: flex;

  .message__wrapper--users {
    width: 300px;
    border-right: solid 1px #e5e5e5;
    height: 100%;
    overflow-y: auto;
    flex-shrink: 0;
    cursor: pointer;

    .message__name {
      padding: 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #cecece;
      border-right: 6px solid transparent;

      &.active {
        border-right-color: #fff;
        background-color: #612166; 
      }

      &.unseen {
        
      }

      > div {
        width: 100%;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        color: #292d32;

        &:not(.name__date) {
          max-width: 215px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-top: 5px;
        }

        &.name__date {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          color: #000;

          span {
            font-size: 10px;
            font-weight: normal;
            color: #6f7172;
          }
        }
      }
    }
  }

  .chat__avatar {
    display: inline-flex;
    width: 50px;
    height: 50px;
    background-color: #612166;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-right: 12px;
    flex-shrink: 0;
    border-radius: 50%;
  }

  .message__chat--details {
    padding: 22px;
    padding-bottom: 0;
    width: calc(100% - 300px);
    overflow: hidden;

    .chat__header {
      display: flex;
      border-bottom: 1px solid #cecece;
      padding-bottom: 22px;

      > div {
        width: 100%;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        color: #000;

        &:not(.name__date) {
          max-width: 215px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-top: 5px;
        }

        &.name__date {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          color: #000;

          span {
            font-size: 10px;
            font-weight: normal;
            color: #6f7172;
          }
        }
      }
    }

    .chat__body {
      margin-top: 50px;
      height: calc(100% - 204px);
      overflow-y: auto;
      
      .chat--message {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;

        p {
          padding: 16px 30px;
          border-radius: 10px;
          margin-bottom: 10px;
        }

        &.from {
          justify-content: flex-start;

          p {
            background-color: #e5e5e5;
          }
        }

        &.to {
          justify-content: flex-end;

          p {
            background-color: #e8faff;
          }

          span {
            justify-content: flex-end;
          }
        }

        span {
          display: inline-flex;
          width: 100%;
          font-size: 10px;
          color: #6f7172;
        }
      }
    }
  }

  @include mq("ipad") {
    flex-direction: column;

    .message__wrapper--users {
      height: auto;
      width: 100%;
      display: flex;
      overflow-x: auto;
      border-bottom: solid 1px #e5e5e5;

      .message__name {
        padding: 15px 6px;
        display: flex;
        align-items: center;
        border-right: 1px solid #cecece;
        border-bottom: 6px solid transparent;
        width: 200px;

        &.active {
          border-right-color: #cecece;
          border-bottom-color: #fff;
          background-color: #612166;
        }

        &.unseen {
          
          border-right-color: #cecece;
          border-bottom-color: #fff;
        }

        .chat__avatar {
          width: 30px;
          height: 30px;
          font-size: 12px;
        }

        .name__date {
          font-size: 10px;

          span {
            font-size: 8px;
          }
        }
      }
    }

    .message__chat--details {
      padding: 22px;
      width: 100%;
    }
  }
}

.message__send {
  display: flex;
  height: 80px;
  background-color: #f0f0f0;
  align-items: center;
  margin: 0 -22px;
  direction: rtl;
  padding-left: 20px;
  padding-right: 12px;

  .ant-input {
    background-color: transparent;
    border: none;
    height: 100%;
  }
  
  button {
    background-color: transparent;
    border: none;
  }
}