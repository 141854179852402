@import "../../styles/utilities";
html{
  font-size: 7px;
}
.order_details_page {
  .order_details_Holder {
    .section {
      margin: 8rem 0;
      .section_title {
        font-size: 4rem;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 0.32px;
        color: $color-secondary;
        margin-bottom: 4rem;
      }
      .section_body {
        padding: 3rem 5.5rem;
        border-radius: 26px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
        overflow: hidden;

        &.pad_less {
          padding: 0;
        }

        @include mq("md") {
          padding: 3rem 2rem;
        }
      }
    }
  }
}

.order {
  padding: 0 14px 3rem ;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &:lang(ar){
    padding: 0  5.5rem 3rem 14px;
  }
  .order_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4rem;
    // flex-wrap: wrap;
    margin-bottom: 2rem;
    h4 {
      margin-top: 2rem;
      font-size: 4rem;
      font-weight: 500;
      //   line-height: 3.38;
      letter-spacing: normal;
      color: $color-black-3;
      text-transform: capitalize;
      span {
        color: #7b7677;
      }
    }
    .order_price {
      width: 23.2rem;
      height: 7.8rem;
      border-radius: 0 26px 0 0;
      background-color: #5e1e63;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 2.2;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }
  .order_location {
    display: flex;
    justify-content: flex-start;
    gap: 6rem;
    .location_diagram {
      padding-top: 1rem;
      display: flex;
      //   flex: 1 0 4rem;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .logo_holder {
        img {
          //   width: 4rem !important;
        }
      }
      .line_holder {
        flex: 1;
        * {
          height: 100% !important;
        }
      }
    }
    .location_text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      div {
        h5 {
          font-size: 3.5rem;
          font-weight: 500;
          letter-spacing: normal;
          color: #2e2e2e;
          text-transform: capitalize;
        }
        p {
          font-size: 2.5rem;
          line-height: normal;
          letter-spacing: normal;
          color: #7b7677;
          font-weight: 500;
        }
      }
    }
  }
  .order_date {
    align-self: flex-end;
    font-size: 3rem;
    line-height: normal;
    letter-spacing: normal;
    color: #9f9f9f;
    padding-right: 3rem;
  }

  @include mq("md") {
    padding: 0rem 0 3rem 2rem;
    .orader {
      gap: 3rem;

      h4 {
        font-size: 3rem;
      }
      .order_price {
        width: 18rem;
        height: 7rem;

        span {
          font-size: 2.5rem;
        }
      }
    }
    .order_location {
      gap: 4rem;
      .location_diagram {
        .logo_holder {
          img {
            width: 4rem !important;
          }
        }
      }
      .location_text {
        div {
          h5 {
            font-size: 3rem;
          }
          p {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}

.delivary_info {
  .info {
    img {
      padding-right: 3rem !important;
      width: 14rem !important;
    }
    h5 {
      font-size: 2.7rem;
      font-weight: 500;
      letter-spacing: normal;
      color: #393939;
      text-transform: capitalize;
    }
    p {
      font-size: 2.7rem;
      letter-spacing: normal;
      color: #808080;
      text-transform: capitalize;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
      width: 8rem !important;
    }
  }
}

.order_info {
  .head {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: normal;
    color: #2e2e2e;
  }
  text-transform: capitalize;
  .desc {
    font-size: 2.5rem;
    letter-spacing: normal;
    color: #7b7677;
    text-transform: capitalize;
  }
}

.delivary_time {
  p {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: normal;
    color: #2e2e2e;
  }
}
.delivary_notes {
  p {
    font-size: 2.7rem;
    letter-spacing: normal;
    line-height: normal;
    color: #919191;
  }
}

.delivary_company {
  padding: 3rem 0rem;
  .item_info {
    display: flex;
    gap: 4rem;

    .text_holder {
      h2 {
        font-size: 3.2rem;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 2.2rem;
      }
    }
  }
  .item_price {
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;
    font-size: 3rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    color: $color-primary;
    text-transform: uppercase;
    span {
      color: #000;
    }
  }
}

.payment_owner {
  p {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: normal;
    color: #2e2e2e;
  }
}

.order_type {
  display: flex;
  align-items: center;
  gap: 2.8rem;
  p {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: normal;
    color: #2e2e2e;
  }
}

.delivery_cost {
  .head {
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: normal;
    color: #2e2e2e;
  }
  text-transform: capitalize;
  .desc {
    font-size: 2.5rem;
    letter-spacing: normal;
    color: #7b7677;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.box_btns {
  margin-bottom: 4rem;
  display: flex;
  gap: 1rem;
  .btn_cancel {
    background-image: none !important;
    background-color: $color-black-3 !important;
  }

  @include mq("md") {
    flex-direction: column;
  }
}
