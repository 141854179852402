.assign__cate--header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #612166;

    p {
      &:first-child {
        margin-right: 20px;
      }
    }
  }