.purchase__wrapper {
  background-color: #fff;
  .ant-table {
    .ant-table-thead {
      tr {
        th {
          text-align: left;
          padding-left: 25px;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          text-align: left;
          padding-left: 25px;
          border: none;

          .ant-input {
            width: 115px;
            height: 42px;
            border-radius: 10px;
            border: solid 1px #dbdbdb;
            background-color: #fff;
          }
        }

        &:nth-child(odd) {
          td {
            background-color: #fff;
          }
        }

        &:nth-child(even) {
          td {
            background-color: #fff;
          }
        }
      }
    }
  }

  .total__amount {
    display: flex;
    justify-content: space-between;
    padding: 18px 40px;

    > p {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    div {
      display: flex;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
        margin: 0 14px;

        span {
          color: #6f7172;
          display: inline-block;
          margin-right: 7px;
        }
      }
    }
  }
}
