.newsFeed__wrapper {
  .post__panel--box {
    padding: 25px 20px;
    border-radius: 10px;
    border: solid 1px #cecece;
    background-color: #fff;
    margin-bottom: 50px;

    .input-wrapper {
      direction: rtl;

      .ant-input {
        height: 100px;
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #cecece;

        &::placeholder {
          font-size: 12px;
          font-weight: bold;
          color: #00b2f3;
        }
      }

      .media-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        > div {
          display: flex;
          align-items: center;

          button {
            border: none;
            font-size: 14px;
            font-weight: 600;
            color: #fff;

            img {
              display: inline-block;
              margin-left: 14px;
              width: 20px;
            }
          }
        }
      }
    }
  }
}

.posts__body--wrapper {
  direction: rtl;
  .post__info {
    padding: 30px 40px 0;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    border-radius: 10px;

    .post--header {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }

      .post__date {
        font-family: Cairo;
        font-size: 12px;
        color: #6f7172;
        direction: ltr;
      }
    }

    .post--body {
      p {
        font-size: 12px;
        font-weight: normal;
        color: #000;
        margin: 10px 0;
      }
    }

    .post__actions {
      display: flex;
      justify-content: space-between;
      border-top: solid 1px #e5e5e5;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-basis: 50%;

        &:first-child {
          border-left: 1px solid #e5e5e5;
        }
      }
    }
  }

  .post__media {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0;

    img {
      display: inline-block;
      margin: 0 10px;
      border-radius: 10px;
      border: solid 2px #dbdbdb;
      width: 150px;
      height: 150px;
    }
  }
}

.comment__wrapper {
  .comment-info {
    background-color: #f1f1f1;
    padding: 25px;

    h2 {
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: #6f7172;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      color: #000;
    }
  }
}

.post__media--edit {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 25px 0;

  img {
    display: inline-block;
    margin: 0 10px;
    border-radius: 10px;
    border: solid 2px #dbdbdb;
    width: 80px;
    height: 80px;
  }
}
