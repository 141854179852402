@import "../../../../styles/mixins";

.product__wrapper--add {
  padding: 34px 65px;
  direction: rtl;
  .ant-upload-list{
    max-width: 439px;
    .ant-upload-list-item-card-actions-btn:focus, .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn{
      background-color: transparent;
      border: 0;
      margin: 0;
    }
    .ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight{
      display: none; 
    }
  }
  .ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-text-icon > .anticon, .ant-upload-list-item-error .ant-upload-list-item-name{
    color: #000;
  }
  .ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{
    border-color: #d9d9d9;
  }
  @include mq("ipad") {
    padding: 20px !important;


    .ant-form-item-label {
      text-align: right;
    }
  }

  *:not(label) {
    // direction: ltr !important;
    direction: rtl !important;
    &:lang(ar){
      direction: rtl !important;
    }
  }

  .product__title--page {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    text-align: right;
    direction: ltr !important;
  }

  .form__wrapper--product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-form-item {
      flex-basis: calc(50% - 10px);
      // flex-basis: 100% !important;
      &.full-width{
        flex-basis: 100% !important;
      }
      @include mq("ipad") {
        flex-basis: 100% !important;
      }

      .ant-form-item-control-input {
        max-width: 100% !important;
      }

      &.price__item {
        .ant-form-item-control-input-content {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          .price__label {
            font-size: 14px;
            font-weight: bold;
            color: #000;
            display: inline-block;
            margin-left: 20px;
            flex-shrink: 0;
          }

          .ant-input {
            max-width: 242px;
          }
        }
      }
    }

    .choose__group {
      margin-top: 18px;
      border-top: 1px solid #dbdbdb;
      padding-top: 18px;
      margin-bottom: 10px;
      width: 100%;

      .choose__group--container {
        border-radius: 10px;
        padding: 25px 30px;
        background-color: #e8faff;
        transition: all 0.4s ease-in-out;

        &.collapsed {
          height: 85px;
          overflow: hidden;
        }

        p {
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 30px;

          span {
            cursor: pointer;
          }
        }

        .ant-form-item {
          flex-direction: row;
          align-items: center;

          .ant-form-item-label {
            margin-bottom: 0;
            > label {
              font-size: 14px;
              font-weight: bold;
              color: #000;
              margin-bottom: 0;
            }
          }

          .ant-input {
            margin-left: 25px;
            margin-right: 30px;
          }

          .price__label {
            font-weight: 500;
          }
        }
      }
    }
  }

  .product__tags {
    width: 100%;
    max-width: 660px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    padding: 30px;

    .ReactTags__selected {
      display: flex;
      flex-wrap: wrap;
      //   ReactTags__tag
      .tag-wrapper {
        height: 42px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        padding: 8px 20px;
        border-radius: 10px;
        background-color: #612166;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        direction: ltr !important;
        margin-bottom: 10px;

        button {
          width: 20px;
          height: 20px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: #612166;
          border: none;
          margin: 0;
          margin-left: 12px;
          cursor: pointer;
          color: #fff;
        }
      }

      .ReactTags__tagInputField {
        border: none !important;
        border-color: transparent !important;

        &:focus-visible,
        &:focus,
        &:active {
          border-color: transparent !important;
        }
      }
    }
  }

  .upload__pdf {
    width: 233px;
    padding: 16px;
    border-radius: 10px;
    border: dashed 2px #e5e5e5;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #6f7172;
  }

  .wrapperClassName.rdw-editor-wrapper {
    * {
      direction: ltr !important;
    }

    .rdw-editor-toolbar {
      justify-content: center;
      border: solid 1px #e5e5e5;
      padding: 18px;
      background-color: #f7f8fa;
    }

    .rdw-editor-main {
      border-radius: 10px;
      border: solid 1px #dbdbdb;
      background-color: #fff;
      min-height: 182px;
      margin-top: 18px;
      padding: 0 20px;
    }
  }

  .image__upload--btn {
    font-size: 30px;
    font-weight: 600;
    color: #c9c5c5;
  }
}

.ant-select-dropdown {
  max-width: 100% !important;
  min-width: unset !important;
}

.ant-upload.ant-upload-select-picture-card {
  background-color: #fff ;
}