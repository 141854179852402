@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$breakpoints: (
  'xs-phone': 360px,
  'phone': 400px,
  'phone-wide': 480px,
  'phablet': 576px,
  'tablet-small': 640px,
  'tablet': 768px,
  'ipad': 992px,
  'tablet-wide': 1024px,
  'desktop': 1200px,
  'small-desktop': 1100px,
  'common-desktop': 1366px,
  'desktop-wide': 1440px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
