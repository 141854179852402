@import "../../../styles/mixins";

.product__wrapper {
  border-radius: 12px;
  overflow: hidden;

  .product__wrapper--header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #612166;

    p {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .table__wrapper {
    border-radius: 0;
  }
}

.table__filter {
  display: flex;
  align-items: center;

  .table__filter--count {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
      margin: 0 18px 0 0;
    }

    span {
      margin: 0 20px 0 7px;
      font-size: 14px;
      font-weight: normal;
      color: #292d32;
      display: inline-block;
    }
    .ant-select {
      .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
        width: 64px;
        border-radius: 6px;
        border: solid 1px #dbdbdb;

        .ant-select-selection-item {
          margin: 0 7px 0 0;
          padding: 0;
        }
      }
      .ant-select-arrow {
        right: 0;
        font-size: 10px;
        top: 14px;
      }
    }
  }

  .product__btn {
    .secondary {
      margin-right: 10px;

      @include mq("ipad") {
        margin-bottom: 5px;
      }
    }
  }
}

.add-promotion {
  font-size: 12px;
  font-weight: 600;
  color: #612166;
  text-decoration: underline;
  cursor: pointer;
}

.add__record--modal {
  .form__wrapper .ant-form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    direction: rtl;
  }

  .form__wrapper .ant-form-item .ant-form-item-control .ant-input {
    width: 100%;
    max-width: 439px;
    height: 60px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    direction: rtl;
  }

  .ant-picker {
    width: 100%;
    max-width: 439px;
    height: 60px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    direction: rtl;
  }
}
