@import "../../styles/mixins";

.dashboard__wrapper {
  > h2 {
    opacity: 0.9;
    font-size: 20px;
    font-weight: bold;
    color: #000;
    margin-bottom: 16px;
  }

  .dashboard__wrapper--first {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 20px;

    @include mq("ipad") {
      grid-template-columns: repeat(1, 1fr);
    }

    .ant-card {
      border-radius: 10px;
      box-shadow: 0 20px 40px 0 rgba(175, 185, 188, 0.25);
      background-image: linear-gradient(127deg, #fff 13%, #e8faff 155%);
      padding: 25px 25px 18px;
      @include flexCenter;

      &.card-view-holder {
        text-align: center;

        .ant-card-body {
          padding: 0;
          width: 100%;
          svg {
            margin-bottom: 8px;
            font-size: 34px;
          }
        }
        
        img, svg {
          margin-bottom: 8px;
        }

        .ant-card-meta-detail {
          .ant-card-meta-title {
            p {
              display: flex;
              flex-direction: column-reverse;
              align-items: center;
              font-size: 12px;
              font-weight: 600;
              // color: #fff;
              margin: 8px 0 12px;
            }
          }
        }

        .second__text {
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          margin-bottom: 12px;

          &.number {
            font-size: 30px;
            font-weight: bold;
            color: #000;

            > span {
              font-size: 12px;
              font-weight: normal;
              color: #000;
              display: inline-block;
              margin-right: 8px;
            }
          }
        }

        .card__footer-wrapper {
          display: flex;
          justify-content: space-between;
          padding-top: 20px;
          margin-top: 20px;
          border-top: 1px solid #e8e8e8;
          flex-wrap: wrap;

          .second__text {
            margin-bottom: 0;

            &.number {
              font-size: 20px;
              font-weight: bold;
              color: #000;
            }
          }

          > span {
            display: block;
            width: 100%;
            font-size: 12px;
            font-weight: normal;
            color: #000;
            margin-top: 20px;
          }
        }
      }
    }

    &.second-row {
      grid-template-columns: repeat(4, 1fr);

      @include mq("ipad") {
        grid-template-columns: repeat(1, 1fr);
      }

      .ant-card {
        background-color: #fff;
        background-image: none;

        .second__text {
          &.number {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
          }
        }

        .card__footer-wrapper {
          padding-top: 0;
          margin-top: 0;
          border-top: none;
        }
      }
    }

    &.third-row {
      .ant-card {
        background-color: #fff;
        background-image: none;

        &.ant-card.card-view-holder {
          padding: 18px 25px;

          .ant-card-body {
            width: 100%;
          }

          .card__header--wrapper {
            flex-direction: row-reverse;
            @include flexCenter;

            .ant-card-meta-detail {
              .ant-card-meta-title {
                p {
                  margin-right: 25px;
                }
              }
            }
          }
        }
      }
    }
  }

  .subscription__date {
    p {
      font-size: 12px !important;
      font-weight: normal !important;
      color: #6f7172 !important;
    }
  }
}

.charts__wrapper {
  .cline__charts--view {
    border-radius: 10px;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;

    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
      color: #fff;
    }
  }

  @include mq("tablet-wide") {
    flex-direction: column;

    .cline__charts--view {
      &.pie {
        flex-basis: 100%;
      }
    }
  }
}


.calender-icon {

  float: right;
  display: inline-flex;
  margin-left: 7px;
  margin-top: 4px;

}