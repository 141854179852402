@import "../../styles/mixins";

.login__wrapper {
  background-color: #f1f1f1;
  height: 100vh;
  @include flexCenter;

  .login__wrapper--center {
    background-color: #fff;
    padding: 50px;
    border-radius: 10px;
    max-width: 554px;
    width: 100%;

    .login__logo {
      text-align: center;
      margin-bottom: 35px;

      p {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin: 0;

        &:first-of-type {
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
        }
      }
    }

    .login__form {
      .ant-form-item {
        flex-direction: column;
        margin-bottom: 20px;

        .ant-form-item-label {
          > label {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            height: auto;
            margin-bottom: 17px;

            &::before {
              color: #fff;
            }

            &::after {
              display: none;
            }
          }
        }

        .ant-form-item-control-input {
          text-align: right;

          .ant-input,
          .ant-input-password {
            max-width: 439px;
            width: 100%;
            height: 60px;
            flex-grow: 0;
            padding: 0 20px;
            border-radius: 10px;
            border: solid 1px #dbdbdb;
            background-color: #fff;
          }

          .ant-input-password {
            .ant-input {
              border-radius: 0;
              border: none;
              background-color: #fff;
              height: auto;
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
        .ant-form-item-explain-error {
          text-align: right;
        }
      }

      .login-btn {
        @include flexCenter;
        margin-top: 30px;

        .ant-btn {
          width: 136px;
          height: 50px;
          @include flexCenter;
          border-radius: 10px;
          background-color: #612166;
          font-size: 14px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
}
