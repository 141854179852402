.order__details--info {
  padding: 30px 80px;
  .order__details--status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    > p {
      display: flex;

      &:first-child {
        font-size: 14px;
        font-weight: 600;
        color: #000;

        span {
          color: #6f7172;
          display: inline-block;
          margin:0 8px;
        }
      }

      &:last-child {
        color: #292d32;

        span {
          margin-left: 10px;
        }
      }
    }
  }

  .order__info {
    border: solid 1px #dbdbdb;
    border-radius: 10px;
    overflow-y: hidden;

    .order__info--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 100px;
      height: 58px;
      margin: 0 0 26px;
      padding: 18px 185px 18px 117px;
      background-color: #dbdbdb;

      p {
        font-size: 12px;
        font-weight: 600;
        color: #000;
      }
    }
  }

  .order__products {
    border-bottom: 1px solid rgba(150, 150, 150, 0.16);

    .order__products-info {
      padding: 20px 100px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .order__products--details {
        display: flex;
        align-items: center;

        > img {
          width: 85px;
          height: 85px;
          flex-grow: 0;
          margin: 0 24px 0 0;
          border-radius: 5px;
          border: solid 1px rgba(150, 150, 150, 0.16);
        }

        .order__title {
          margin-left: 24px;

          p {
            margin-bottom: 5px;

            &:first-of-type {
              font-size: 12px;
              font-weight: 600;
              color: #000;
            }

            &:nth-of-type(2) {
              font-size: 12px;
              font-weight: 600;
              color: #828385;
            }

            &:last-of-type {
              font-size: 14px;
              font-weight: normal;
              color: #020202;
            }
          }
        }
      }

      .price {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
  }

  .order__info--address {
    margin-top: 20px;
    padding: 0 100px;

    p {
      font-size: 12px;
      font-weight: normal;
      color: #fff;
      margin-bottom: 20px;

      span {
        display: block;
        font-weight: 600;
        margin-bottom: 2px;
        color: #000;
      }
    }
  }

  .order__info--footer {
    padding: 12px 15px 6px;
    background-color: #dbdbdb;

    p {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
      width: 60%;
      margin: 0 auto 6px;

      span {
        color: #000;
      }
    }
  }
}
