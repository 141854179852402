@import '../../../styles/mixins';

.ant-layout-header {
  // background-color: #612166;
  padding: 24px 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 89px;

  .ant-dropdown-link {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #612166;
    margin-left: 30px;
    cursor: pointer;
    &:lang(ar){
      margin-left: 0;
      margin-right: 30px;
    }
  }

  .ant-progress-circle-trail {
    stroke: #612166;
  }
  
  .ant-progress-circle-path {
    stroke: #612166;
  }

  @include mq('ipad') {
    padding: 24px 15px;

    .ant-dropdown-link {
      font-size: 10px;
    }
  }
}

.header__right {
  border-left: 1px solid #cecece;
  display: flex;
  align-items: center;
  // padding-left: 30px;
  height: 45px;
  margin-left: 20px;
  &:lang(ar){
    border-left: none;
    border-right: 1px solid #cecece;
    margin-left: 0;
    margin-right: 20px;
  }
  .supplier_logo {
    display: inline-block;
    width: 45px;
    height: 45px;
    border: solid 1px #e5e5e5;
    border-radius: 50%;
    margin-right: 20px;
  }
  .ant-avatar{
    margin-right: 10px;
    &:lang(ar){
      margin-right: 0;
      margin-left: 10px;
    }
  }
}


.btn_notif {
  width: 4.4rem;
  height: 4.4rem;
  padding: 1rem;
  border-radius: 7.6rem;
  background-color: #612166;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.header_dropdown_User_actions {
  background-color: #fff;
  padding: 2rem 0 1rem;
  border-radius: 1rem;
  max-height: 60vh;
  overflow-y: auto;
  h4 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #612166;
    margin-bottom: 2rem;
    padding: 0 3rem;
  }

  .list {
    li {
      padding: 1.5rem 3rem;
      margin: 0 !important;
      height: auto;
      display: flex;
      align-items: center;
      gap: 1.5rem;

      &:hover {
        background-color: #deeff8;
      }
      // &[class*="ant-menu-item-selected"] {
      //   background-color: #deeff8;
      // }

      .icon {
        min-width: 32px;
        height: 32px;
        padding: 0.8rem;
        background-color: #612166;
        border-radius: 50%;
      }
      .item_content {
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        p {
          font-size: 16px;
          line-height: 1.6;
          max-inline-size: 28ch;
          white-space: pre-wrap !important;
          color: #000;
        }
        span {
          font-size: 14px;
          line-height: 1.6;
          color: #612166;
          color: gray;
          // align-self: flex-start;
        }
        button {
          min-width: 4rem;
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}
