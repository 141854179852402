@import "./styles/mixins";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
  font-family: "Cairo", sans-serif;
  &:lang(ar){
    direction: rtl;
  }
}

p {
  margin: 0;
}
* {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
    height: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.content__wrapper {
  border: solid 1px rgba(175, 185, 188, 0.25);
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;

  .content__header {
    height: 66px;
    background-color: #612166;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
  }
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 14px;
  border-radius: 10px;
  background-color: #612166;
  border: solid 1px #612166;
  font-size: 14px;
  font-weight: 600;

  &.ant-btn-primary {
    background-color: #612166;
    color: #fff;
    border-color: #612166;
  }

  &.primary {
    background-color: #612166;
    color: #fff;
  }

  &.secondary {
    color: #fff;
  }

  &.default {
    background-color: #612166;
    color: #fff;
    border-color: #612166;
  }
}

.ant-layout-content {
  height: calc(100vh - 90px);
  overflow: auto;

  &::-webkit-scrollbar-thumb {
    background: #fff;
  }
}

.ant-select {
  max-width: 439px;
}

.inter {
  font-family: "Inter", sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search__list--input {
  .ant-input {
    max-width: 220px;
    height: 45px;
    flex-grow: 0;
    padding: 0 20px;
    border-radius: 10px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
  }
}

.download__list {
  border: solid 1px #e8e8e8;
  background-color: #f8f8f8;
  border-radius: 4px;
  display: flex;

  > a {
    width: 63px;
    height: 35px;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    font-family: "Inter", sans-serif !important;
    border-right: solid 1px #e8e8e8;
  }

  &:last-child {
    border-right: none;
  }
}

.ltr-direction {
  direction: ltr !important;
  // &:lang(ar){
  //   direction: rtl !important;
  // }
}

.rtl-direction {
  direction: rtl !important;
  // &:lang(ar){
  //   direction: ltr !important;
  // }
}

.bold {
  font-weight: bold !important;
}

.normal-font {
  font-weight: 500 !important;
}

.table__hidden {
  display: none;
}

.dropdown__column--visible {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(175, 185, 188, 0.25);
  border-radius: 6px;
  padding: 10px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.selector-more-width {
  .ant-select-selector {
    width: 170px !important;
  }
}

.primary-text {
  color: #fff;
}

.ant-select {
  &.ant-select-multiple {
    .ant-select-selector {
      height: auto !important;
      padding: 10px !important;
    }
  }
}

.label-rtl {
  .ant-form-item-label {
    label {
      direction: rtl !important;
    }
  }
}

.modal-order-holder{
  .ant-form-item{
    display: flex;
    flex-direction: column;
    .ant-form-item-label{
      text-align: left;
      &:lang(ar){
        text-align: right;
      }
    }
  }
}
.delivery-col-holder.delivery-col-modal{
  // height: 30px;
  justify-content: flex-start;
}

.PhoneInputCountry {
  background: #fff;
  padding-left: 8px;
  padding-right: 16px;
  margin-left: 0;
  border-radius: 16px;
  // &:lang(ar) {
  //   padding-right: 16px;
  //   padding-left: 8px;
  // }
}

.ant-modal-title{
  &:lang(ar){
    text-align: left;
  }
}

.PhoneInputInput {
  border: 0;
  padding: 0;
  height: 100%;
}

.PhoneInputCountrySelectArrow {
  color: #000;
  opacity: 1;
  font-size: 21px;
  margin-right: 20px;
  // &:lang(ar) {
  //   margin-left: 0;
  //   margin-right: 6px;
  // }
}

.PhoneInput {
  margin-bottom: 0.5rem;
  height: 7.5rem;
  border: 0.5px solid #dbdbdb;
  border-radius: 12px;
  max-width: 439px;
  flex-direction: row-reverse;
  .ant-input-prefix {
    margin: 1rem 0;
    margin-right: 2rem;
    margin-left: 1rem;
    font-size: 2rem;
    img {
      object-fit: contain;
    }
  }
  input {
    letter-spacing: 0.14px;
    // color: #000;
    color: #000;
    font-size: 16px;
    // font-weight: 500;
    border: 0 !important;
    outline: 0 !important; 
    border-radius: 12px;
    direction: ltr; 
    &.PhoneInputInput{
      direction: ltr !important;
    }
  }
  .PhoneInput input {
    height: 100%;
    padding: 0; 
    border: 0 !important;
    outline: 0 !important;
  }
}

.ant-modal-confirm-btns{
  .ant-btn.ant-btn-default{
    color: #fff;
  }
}
.product__wrapper--add .PhoneInput input{
  direction: ltr !important;
}
.col-name-holder{
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-rate{
    zoom: .77;
    .ant-rate-star-zero{
      display: none;
    }
  }
}

.rete-desc{
  font-size: 16px;
      font-weight: 500;
    color: #171717;
}

.ant-input[disabled], .PhoneInput--disabled{
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  opacity: 1;
  .PhoneInputCountry{
    background-color: transparent !important;
  }
}

.ant-pagination-options-size-changer.ant-select{
  display: none;
}

.lang-holder{
  margin: 0 2vw;
  p{
    cursor: pointer;
    font-size: 14px;
    color: #000;
    font-weight: 500;
  }
}

// div.PhoneInput{
//   direction: initial !important;
// }

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn{
  &:lang(ar){
    margin-left: 0;
    margin-right: 8px;
  }
}
.cancel-model-holder{
  margin-top: 20px;
  textarea{
    border-radius: 8px;
    font-size: 16px;
  }
}

.product__wrapper--add input[type=email]{
  direction: ltr !important; 
}