@import '../../../styles/mixins';

.subscription__container {
  border-radius: 10px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
  padding: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  p {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    flex-basis: 45%;
    margin-bottom: 40px;

    @include mq('ipad') {
      flex-basis: 100%;
    }

    &:nth-child(odd) {
      margin-right: 3%;
    }

    img {
      display: inline-block;
      margin-right: 20px;
    }

    span {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      display: inline-block;
      margin-left: 5px;
    }
  }
}
