/************ VARIABLES ********************/
// FONTS
$font-primary: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// COLORS
$color-primary: #612166;
$color-primary-light: #8c4191;
$color-secondary: #294151;
$color-white-1: #ffffff;
$color-white-2: #fcfcfc;
$color-white-3: #f5f5f5;
$color-black-1: #000000;
$color-black-2: #200e32;
$color-black-3: #2e2e2e;
$color-gray-1: #555555;
$color-gray-2: #737681;
$color-gray-3: #7f8897;

// STYLES
$shadow-1: 28px 49px 59px #7171711a;

/************MEDIA QUERY ********************/
$breakpoints: (
  "xs": 480px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1201px,
  "xxl": 1601px,
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 1px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/*****************prfix*************/
@mixin prefixer($property, $val) {
  #{$property}: $val;
  -webkit-#{$property}: $val;
  -moz-#{$property}: $val;
  -ms-#{$property}: $val;
  -o-#{$property}: $val;
}

/*****************input placeholder*************/
@mixin input-placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin margin-hero {
  margin: 10rem 0;

  @include mq("md") {
    margin: 6rem 0;
  }
}
